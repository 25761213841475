import { useCallback, useEffect, useState, } from 'react';
import axios, { isCancel, CancelToken, } from 'src/utils/axios';
import { isEqual, isObjectLike, } from 'lodash';
import CACHE_TIME from 'src/constants/cacheTime';
import { useSnackbar, } from 'notistack';
import { useSelector, } from 'react-redux';
import useCache from './useCache';
import useIsMountedRef from './useIsMountedRef';
import usePrevious from './usePrevious';

/**
 * Async get request that saves to cache context.
 *
 * @typedef {Object} UseFetchRequestParams
 * @property {string} url
 * @property {Object} params? [optional]
 * @property {Object} body? [optional]
 * @property {string} method? [default = 'get']
 * @property {function} beforeFetch? [optional]
 * @property {function} afterFetch? [optional]
 * @property {boolean} autoFetch? [default = true]
 * @property {boolean} displayErrorSnackBar? [default = true]
 * @property {boolean} cancelPrevRequest? [default = true]
 *
 *
 * @typedef {Object} UseFetchResponse
 * @property {any} response FIXME: (jh) Document valid types
 * @property {boolean} isLoading
 * @property {function} setLoading [UNSAFE?] FIXME: (jh) Remove property, only
 * if not currently being used in the app
 * @property {function} clearResponseCache
 * @property {function} fetchData
 *
 * @param {UseFetchRequestParams} asyncHookParams
 * @return {UseFetchResponse}
 */

export function useFetch(...asyncHookParams) {
  const { enqueueSnackbar, } = useSnackbar();
  let [
    url,
    params = null,
    body = null,
    method = 'get',
    beforeFetch,
    afterFetch,
    autoFetch = true,
    displayErrorSnackBar = true,
    cancelPrevRequest = true,
    // Add support for POST to use GQL
    isGraphQLQuery = false,
    gqlService,
    gqlServiceInitialMethod,
    gqlServiceInitialParams,
  ] = asyncHookParams;

  if (isObjectLike(asyncHookParams[0])) {
    [
      {
        url,
        params = null,
        method = 'get',
        body = null,
        beforeFetch,
        afterFetch,
        autoFetch = true,
        displayErrorSnackBar = true,
        cancelPrevRequest = true,
      },
    ] = asyncHookParams;
  }

  const { user, } = useSelector((state) => state.account);
  const [isLoading, setLoading,] = useState(
    autoFetch && (!!url || isGraphQLQuery)
  );
  const [cancelTokenSource, setCancelTokenSource,] = useState(null);
  const { getFromCache, saveToCache, clearCache, } = useCache();
  const isMountedRef = useIsMountedRef();
  const [response, setResponse,] = useState(null);
  const cacheKey = { url, params, agents: user?.agents, };
  const clearResponseCache = () => clearCache && clearCache(cacheKey);
  const previousUrl = usePrevious(url);
  const previousParams = usePrevious(params);
  const previousBody = usePrevious(body);
  const previousAgents = usePrevious(user?.agents);

  const fetchData = useCallback(
    async (forceFetch = false) => {
      const shouldSkip = autoFetch && params !== undefined && body !== undefined && previousUrl === url && isEqual(previousParams, params) && isEqual(previousBody, body) && isEqual(previousAgents, user?.agents) && !forceFetch;

      // Skip if the parameters hasn't changed or if forceFetch is not true.
      if (shouldSkip) {
        return;
      }

      // Check if the previous request should be cancelled.
      const shouldCancel = cancelPrevRequest && cancelTokenSource;
      if (shouldCancel) {
        cancelTokenSource.cancel('Previous request cancelled by user');
      }

      if (isMountedRef.current) {
        setLoading(true);
      }

      // Execute any function provided prior to fetching the data.
      if (beforeFetch) beforeFetch();

      let cachedResponse = getFromCache && getFromCache(cacheKey);
      if (!cachedResponse || forceFetch) {
        try {
          let cancelTokenSrc;
          if (cancelPrevRequest) {
            cancelTokenSrc = CancelToken.source();
            setCancelTokenSource(cancelTokenSrc);
          }

          const shouldUseGQLService = isGraphQLQuery && gqlService && gqlServiceInitialMethod && gqlService[gqlServiceInitialMethod];
          if (shouldUseGQLService) {
            cachedResponse = await gqlService[gqlServiceInitialMethod](gqlServiceInitialParams);
          } else {
            const config = {
              params,
              ...(cancelPrevRequest && { cancelToken: cancelTokenSrc.token }),
            };

            if (method === 'post' || method === 'put' || method === 'patch') {
              cachedResponse = await axios[method](url, body, config);
            } else {
              cachedResponse = await axios[method](url, config);
            }
          }
          // Note: Is this usually double wrapped in data field from GQL calls(?)
          if (cachedResponse?.data?.data) cachedResponse = cachedResponse?.data;
          // Don't cache GQL requests as it already has a caching mechanism
          !isGraphQLQuery && saveToCache && saveToCache(cacheKey, cachedResponse, CACHE_TIME.FIVE_MIN);
        } catch (error) {
          if (cancelPrevRequest && isCancel(error)) {
            return;
          }
          console.error({ error });
          if (displayErrorSnackBar) {
            enqueueSnackbar(
              ` ${method?.toUpperCase() || ''} ${url || ''} failed ${error?.message}: ${error?.response?.data?.message}` || 'Error getting data',
              { variant: 'error', }
            );
          }
        }
      }

      // Execute any function provided after fetching the data.
      if (afterFetch) cachedResponse = afterFetch(cachedResponse);

      if (isMountedRef.current) {
        setResponse(cachedResponse);
        setLoading(false);
      }
    },
    [
      isMountedRef,
      url,
      params,
      getFromCache,
      enqueueSnackbar,
      user,
      displayErrorSnackBar,
      body,
    ]
  );

  useEffect(() => {
    const shouldFetchData = autoFetch && (url || isGraphQLQuery);
    if (shouldFetchData) {
      fetchData();
    }
  }, [fetchData, autoFetch,]);


  return {
    response,
    isLoading,
    setLoading,
    clearResponseCache,
    fetchData,
  };
}

