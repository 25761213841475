/* eslint-disable react/no-array-index-key */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from 'react-feather';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  applyFilters,
  applyPagination,
  applyQuery,
  applySort,
} from 'src/services/tableService';
import { InputField } from 'src';
import NoResults from 'src/components/NoResults';
import CircularLoader from 'src/components/CircularLoader';
import { branch, compose } from "src/utils";
import debounce from 'lodash/debounce';
import withAsync from './withAsync';
import DataTableRow from './TableRow';
import TablePaginationActions from './TablePaginationActions';
import withPagination, { FIRST_PAGE } from './withPagination';
import withLoadMore from './withLoadMore';
import { formatNumber } from 'src/utils/math';
import INPUT_FIELD_TYPE, { INPUT_FIELD_TYPE_VALUES } from 'src/constants/inputFieldType';

// Used to generate firstLetter key on options from customFilters;
// helps the autocomplete sort alphabetically
const generateFirstLetter = (arr) => {
  return arr.map((option) => {
    const firstLetter = option.label[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option
    };
  });
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  queryField: {
    width: 500,
    maxWidth: '100%'
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  inputContainer: {
    '&:not(:last-child)': {
      [theme.breakpoints.up('sm')]: {
        marginRight: 15
      }
    }
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 26,
      marginRight: 0,
      width: '100%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    filterBox: {
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    sort: {
      width: '100%'
    }
  },
  callTableTabs: {
    flex: 1
  },
  downloadButton: {
    width: 48
  },
  noResultsBox: {
    position: 'absolute',
    height: '100%',
    width: '100%'
  },
  noResults: {
    height: 400
  },
  noBorder: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  loadMoreChip: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0
  },
  tableHead: {
    lineHeight: '19px'
  }
}));

/**
 * Comprehensive Table component (with optional tabs) that is used
 * by CrudTablePage to generate a table page with pagination,
 * while fetching data via the url prop. Nearly every aspect of the
 * of the table can be customized
 */
const TabTable = ({
  activeTab,
  className,
  collapsibleTableRowDisplay,
  cursorOnRowHover,
  customFilters,
  displayCheckBoxes,
  displayLoadingRows,
  downloadCsvButton,
  forwardref,
  getServerCsvBlob,
  isLoading,
  idKey,
  loadMoreButton,
  noResultsSettings,
  onChangeActiveTab,
  onChangeSelectedCustomFilter,
  onClickRow,
  onTableDataLoaded,
  paginationSettings,
  paginationState,
  params,
  filterParams,
  responseKey,
  rowLink,
  searchSettings,
  server,
  size,
  skiptoken,
  sortOptions,
  sortSettings,
  tableData,
  tableCellProps,
  tableColBorder,
  tableHeadDisplay,
  tableRowClass,
  tableRowDisplay,
  tabs,
  title,
  totalCount,
  url,
  isGraphQLQuery,
  afterInitialFetch,
  gqlService,
  gqlServiceInitialMethod,
  gqlServiceInitialParams,
  translationStrings: {
    downloadButtonText,
    sortByText,
    deleteButtonText,
    editButtonText,
    rowsPerPageText,
    ofNRowsText,
    moreThanNRowsText,
    loadMoreButtonText
  },
  ...rest
}) => {
  const classes = useStyles();
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [pagination, setPagination] = paginationState;
  const { filters, limit, page, query, sort } = pagination;
  const customFiltersAndTabs = [...(customFilters ?? []), tabs].filter((val) => val);
  const selectedFilters = {
    ...(filters ?? {}),
    ...(tabs && activeTab !== '' && { [tabs.name]: activeTab })
  };

  useEffect(() => {
    setPagination((prevPagination) => {
      const { skiptoken: prevSkiptoken } = prevPagination;
      return {
        ...prevPagination,
        ...(typeof prevSkiptoken !== 'undefined' && { skiptoken: null })
      };
    });
  }, [params, setPagination]);

  useEffect(() => {
    goToFirstPage();
  }, [activeTab, url]);

  const changeSelectedCustomFilter = (customFilterName, newValue) => {
    setPagination((prevPagination) => {
      const { skiptoken: prevSkiptoken } = prevPagination;
      const newFilters = {
        ...prevPagination.filters,
        [customFilterName]: newValue
      };
      // remove cutom filter key if value is '';
      // actually this step is needed for 'all'
      const { [customFilterName]: _, ...newFiltersWithoutBlankFilter } =
        newFilters;
      return {
        ...prevPagination,
        ...(typeof prevSkiptoken !== 'undefined' && { skiptoken: null }),
        filters: newFilters
      };
    });
  };

  const goToFirstPage = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: FIRST_PAGE
    }));
  };

  const handleTabsChange = (event, value) => {
    onChangeActiveTab(value);
    goToFirstPage();
  };

  const debouncedSearch = useCallback(
    debounce(
      (searchQuery) => {
        setPagination((prevPagination) => {
          const { skiptoken: prevSkiptoken, ...restPrevPagination } =
            prevPagination;
          const newPagination = {
            ...restPrevPagination,
            query: searchQuery,
            ...(typeof prevSkiptoken !== 'undefined' && { skiptoken: null }),
            ...(typeof prevSkiptoken === 'undefined' && { page: FIRST_PAGE })
          };
          // remove query key if value is ''
          const { query: _, ...newPaginationWithoutQuery } = newPagination;
          return searchQuery === '' ? newPaginationWithoutQuery : newPagination;
        });
      },
      server === true ? 250 : 0
    ),
    []
  );

  const handleQueryChange = (event) => {
    const query = event.target.value.trim();
    debouncedSearch(query);
  };

  const handleSortChange = (event) => {
    const newValue = event.target.value;
    setPagination((prevPagination) => {
      const { skiptoken: prevSkiptoken, ...restPrevPagination } =
        prevPagination;
      return {
        ...restPrevPagination,
        ...(typeof prevSkiptoken !== 'undefined' && { skiptoken: null }),
        sort: newValue
      };
    });
  };

  const handleCustomFilterChange =
    (customFilter) => (event, newValue) => {
      const { type = INPUT_FIELD_TYPE.SELECT } = customFilter;
      let finalValue = event.target.value;

      if (type === INPUT_FIELD_TYPE.AUTOCOMPLETE_SELECT) {
        finalValue = newValue?.value ?? null;
      } else if (type === INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT) {
        if (customFilter.isAutoComplete) {
          finalValue = newValue?.value ?? null;
        } else {
          finalValue = newValue;
        }
      }
      // The value of event.target.value is always 0 on an autocomplete, need 2nd arg to get values
      // In the event the autocomplete select has text typed in manually event.target.value
      // will have the partial text; in this scenario use the full value they choose from the select

      changeSelectedCustomFilter(customFilter.name, finalValue);
      onChangeSelectedCustomFilter(customFilter.name, finalValue);
      if (customFilter.onChange) customFilter.onChange(event, finalValue);
      goToFirstPage();
    };

  const handleSelectAllTableData = (event) => {
    setSelectedTableData(
      event.target.checked ? tableData.map((tableRow) => tableRow[idKey]) : []
    );
  };

  const handleSelectOneTableRow = (event, tableRowId) => {
    if (!selectedTableData.includes(tableRowId)) {
      setSelectedTableData((prevSelected) => [...prevSelected, tableRowId]);
    } else {
      setSelectedTableData((prevSelected) => {
        return prevSelected.filter((id) => id !== tableRowId);
      });
    }
  };

  // Gets the first row number of a page. The first row number of page 3 with
  // 10 rows per page is 20
  const getFirstRowNumber = (pageNumber, numRowsPerPage) => {
    return (pageNumber - FIRST_PAGE) * numRowsPerPage;
  };

  // Gets the page number from the row number. Row number 20 with 10 rows
  // per page is on page number 3
  const getPageNumber = (rowNumber, numRowsPerPage) => {
    return Math.floor(rowNumber / numRowsPerPage) + FIRST_PAGE;
  };

  const handlePageChange = (event, newPage) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: newPage + FIRST_PAGE
    }));
  };

  const handleLimitChange = (event) => {
    const firstRowNumber = getFirstRowNumber(page, limit);
    const newLimit = event.target.value;
    // make sure first row before change is still present after changing
    // the rows per page (limit)
    const newPageNumber = getPageNumber(firstRowNumber, newLimit);
    setPagination((prevPagination) => ({
      ...prevPagination,
      limit: newLimit,
      page: newPageNumber
    }));
  };

  const getSearchPlaceholder = (placeholderInput) => {
    if (typeof placeholderInput === 'function') {
      return placeholderInput({ count: totalCount });
    }

    return placeholderInput;
  };

  const handleLoadMore = () => {
    setPagination((prevPagination) => ({ ...prevPagination, skiptoken }));
  };

  // filter, sort, and paginate using server or client depending on server prop
  let currentTableData;
  let tablePaginationCount;
  let sortedTableData;
  if (server === true) {
    currentTableData = tableData;
    tablePaginationCount = totalCount ?? 0;
  } else {
    // Here is where the data is filtered client side
    const preProcessedTableData =
      tabs && tabs.preProcess
        ? tabs.preProcess(tableData, activeTab)
        : tableData;
    const queriedTableData = applyQuery(
      preProcessedTableData,
      query,
      searchSettings
    );
    const filteredTableData = applyFilters(
      queriedTableData,
      customFiltersAndTabs,
      selectedFilters
    );
    sortedTableData = sort
      ? applySort(filteredTableData, sort)
      : filteredTableData;
    currentTableData =
      paginationSettings?.paginate === true
        ? applyPagination(sortedTableData, page, limit)
        : sortedTableData;
    tablePaginationCount = sortedTableData.length;
  }

  // client csv download
  const getClientCsvBlob = () => {
    const tableDataIndecesToNotIncludeInFile = new Set();
    const csvData = sortedTableData
      .map(
        (tableRow) =>
          `"${tableRowDisplay(tableRow)
            .filter((tableCell, ind) => {
              if (
                tableCell === null ||
                typeof tableCell === 'string' ||
                typeof tableCell === 'number'
              ) {
                return true;
              }
              tableDataIndecesToNotIncludeInFile.add(ind);
              return false;
            })
            .join('","')}"`
      )
      .join('\n');

    // create table header
    const csvHeader = `"${tableHeadDisplay()
      .filter((tableHeadTitle, ind) => {
        return tableDataIndecesToNotIncludeInFile.has(ind) === false;
      })
      .join('","')}"`;

    // download csv file
    return new Blob([`${csvHeader}\n${csvData}`], { type: 'text/csv' });
  };

  const handleDownloadTable = async () => {
    const csvBlob =
      server === true ? await getServerCsvBlob() : await getClientCsvBlob();

    const downloadLink = document.createElement('a');
    downloadLink.download = `${title}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvBlob);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  if (!tableData) return null;
  const enableBulkOperations = selectedTableData.length > 0;
  const selectedSomeTableData =
    selectedTableData.length > 0 && selectedTableData.length < tableData.length;
  const selectedAllTableData = selectedTableData.length === tableData.length;

  const orderOptions = sortSettings?.options ?? sortOptions;

  // search is complete but no results found
  const noResultsFound = isLoading === false && currentTableData.length === 0;

  useImperativeHandle(forwardref, () => ({
    getSelectedCustomFilter: (customFilterName) => {
      return filters?.[customFilterName];
    },
    setSelectedCustomFilter: (customFilterName, newValue) => {
      changeSelectedCustomFilter(customFilterName, newValue);
      goToFirstPage();
    },
    // set the table paginator back to the first page in case we have a custom
    // filter with a state that isn't managed by this file
    moveToFirstPage: () => {
      goToFirstPage();
    },
    getTableData: () => tableData
  }));


  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {isLoading && <CircularLoader />}
      {tabs ? (
        <>
          <Box display="flex">
            {tabs ? (
              <Tabs
                className={classes.callTableTabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="secondary"
                value={activeTab}
                variant="scrollable"
              >
                {tabs?.options?.map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
            ) : null}
            {downloadCsvButton && (
              <Tooltip title={downloadButtonText}>
                <IconButton
                  className={classes.downloadButton}
                  onClick={handleDownloadTable}
                >
                  <GetAppIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Divider />
        </>
      ) : null}
      {searchSettings || orderOptions || customFilters ? (
        <Box p={2} minHeight={56} display="flex">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              {searchSettings ? (
                <TextField
                  className={classes.queryField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={getSearchPlaceholder(
                    searchSettings?.placeholder
                  )}
                  variant="outlined"
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className={classes.filterBox}
              >
                {customFilters &&
                  customFilters.map((customFilter, index) => {
                    let options = customFilter?.isAutoComplete
                      ? generateFirstLetter(customFilter?.options)
                      : customFilter.options;
                    return (
                      <Box
                        key={`${customFilter?.name}-${index}`}
                        className={classes.inputContainer}
                        style={{
                          width: customFilter?.width || 'auto'
                        }}
                      >
                        {customFilter?.isAutoComplete ? (
                          <Autocomplete
                            id="grouped"
                            className={classes.textField}
                            key={customFilter.name}
                            options={options?.sort(
                              (a, b) =>
                                -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            onChange={handleCustomFilterChange(customFilter)}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, selected) => {
                              return option.value === selected.value;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={customFilter?.label}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        ) : (
                          <InputField
                            key={customFilter.name}
                            className={classes.textField}
                            label={customFilter.label}
                            name={customFilter.name}
                            onChange={handleCustomFilterChange(customFilter)}
                            {...(index === 0 && { autoFocus: true })}
                            fullWidth
                            type={customFilter?.type || INPUT_FIELD_TYPE.SELECT}
                            value={customFilter?.value || (filters ? filters[customFilter.name] : '')}
                            datePickerSettings={
                              customFilter?.datePickerSettings || null
                            }
                            options={options}
                            variant="outlined"
                            placeholder={customFilter?.placeholder}
                            {...customFilter.inputFieldProps}
                          />
                        )}
                      </Box>
                    );
                  })}
                {orderOptions ? (
                  <Box className={classes.inputContainer}>
                    <TextField
                      className={classes.sort}
                      label={sortByText}
                      name="sort"
                      onChange={handleSortChange}
                      select
                      disabled={sortSettings?.disabled}
                      SelectProps={{ native: true }}
                      value={sort}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    >
                      {orderOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllTableData}
              indeterminate={selectedSomeTableData}
              onChange={handleSelectAllTableData}
            />
            <Button variant="outlined" className={classes.bulkAction}>
              {deleteButtonText}
            </Button>
            <Button variant="outlined" className={classes.bulkAction}>
              {editButtonText}
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box
          className={clsx({
            [classes.noResults]: noResultsFound
          })}
        >
          {noResultsFound && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.noResultsBox}
            >
              <NoResults {...noResultsSettings} />
            </Box>
          )}
          <Table {...(size && { size })}>
            <TableHead>
              {!noResultsFound && (
                <TableRow
                  className={clsx({
                    [classes.noBorder]: !tableColBorder
                  })}
                >
                  {collapsibleTableRowDisplay && <TableCell />}
                  {displayCheckBoxes ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllTableData}
                        indeterminate={selectedSomeTableData}
                        onChange={handleSelectAllTableData}
                      />
                    </TableCell>
                  ) : null}
                  {tableHeadDisplay().map((header, ind, arr) => {
                    const sortParts = sort?.split('|');
                    const sortColumn = sortParts?.[0];
                    const sortDirection = sortParts?.[1];
                    return (
                      <TableCell
                        key={ind}
                        className={classes.tableHead}
                        align={arr.length - 1 === ind ? 'right' : 'left'}
                        width={header?.width ?? 'auto'}
                      >
                        {sortSettings?.columnClickable ? (
                          <TableSortLabel
                            active={header?.key === sortColumn}
                            direction={sortDirection}
                            onClick={() =>
                              handleSortChange({
                                target: {
                                  value: `${header?.key}|${sortDirection === 'asc' ? 'desc' : 'asc'
                                    }`
                                }
                              })
                            }
                          >
                            {header?.text ?? header}
                          </TableSortLabel>
                        ) : (
                          header?.text ?? header
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {currentTableData.map((tableRow) => (
                <DataTableRow
                  displayLoadingRows={displayLoadingRows}
                  isLoading={isLoading}
                  idKey={idKey}
                  key={tableRow[idKey]}
                  onClickRow={onClickRow}
                  tableCellProps={tableCellProps}
                  tableRowDisplay={tableRowDisplay}
                  handleSelectOneTableRow={handleSelectOneTableRow}
                  displayCheckBoxes={displayCheckBoxes}
                  cursorOnRowHover={cursorOnRowHover}
                  rowLink={rowLink}
                  tableRow={tableRow}
                  isTableRowSelected={selectedTableData.includes(
                    tableRow[idKey]
                  )}
                  collapsibleTableRowDisplay={collapsibleTableRowDisplay}
                  tableColBorder={tableColBorder}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {paginationSettings?.paginate === true ? (
        <TablePagination
          component="div"
          count={tablePaginationCount === 0 ? -1 : tablePaginationCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page - FIRST_PAGE}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50]}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage={rowsPerPageText}
          labelDisplayedRows={({ from, to, count }) => {
            const fromFormatted = formatNumber(from);
            const toFormatted = formatNumber(to);
            const countFormatted = formatNumber(count);
            return `${fromFormatted}-${toFormatted} ${ofNRowsText} ${count !== -1
              ? countFormatted
              : `${moreThanNRowsText} ${toFormatted}`
              }`
          }}
        />
      ) : null}
      {skiptoken && (
        <CardActions>
          <Button
            fullWidth
            size="medium"
            color="primary"
            onClick={handleLoadMore}
          >
            {loadMoreButtonText}
            <Chip
              className={classes.loadMoreChip}
              size="small"
              label={`${currentTableData.length} ${ofNRowsText} of ${new Intl.NumberFormat().format(totalCount)}`}
            />
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

TabTable.propTypes = {
  /**
   * String corresponding to the current Tab on the table
   * you are using; not used if tabs prop is null / not rendered
   */
  activeTab: PropTypes.string,

  /**
   * Class name to be applied to the outermost element container
   */
  className: PropTypes.string,

  /**
   * Callback function that is passed to the TableRow component;
   * TODO: Figure out where this gets used
   */
  collapsibleTableRowDisplay: PropTypes.func,

  /**
   * Weather to show a cursor icon when a row is hovered indicating
   * it is clickable
   */
  cursorOnRowHover: PropTypes.bool,
  /**
   * Each object in this array is rendered the specified type
   * and must include options if needed; rendered next to the search
   * bar above the table header
   */
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      width: PropTypes.string,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.oneOf(['all']),
            // When using js Date() object
            PropTypes.object
          ]),
          label: PropTypes.string.isRequired
        })
      ),
      onChange: PropTypes.func,
      filter: PropTypes.func,
      isAutoComplete: PropTypes.bool,
      type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES)
    })
  ),
  /**
   * Weather or not to display checkboxes on table headers
   */
  displayCheckBoxes: PropTypes.bool,
  /**
   * Weather or not to render a Skeleton loading animation
   * while a table's row data is loading
   */
  displayLoadingRows: PropTypes.bool,
  /**
   * Weather or not to render a download button on the table
   * to initiate a CSV file download
   */
  downloadCsvButton: PropTypes.bool,
  /**
   * The ref forwared from the CrudTablePage tableSettings; it is
   * used here to add the refesh() function on the ref
   */
  forwardref: PropTypes.any,
  /**
   * Function to initiate the download of the table's data
   * as a CSV file; passed by the withAsync HOC
   */
  getServerCsvBlob: PropTypes.func,
  /**
   * Key name for the id on your resources; usually just 'id'
   */
  idKey: PropTypes.string,
  /**
   * Weather or not the data fetched is still loading, is passed
   * from withAsync HOC after the { useFetch }hook is initiated
   */
  isLoading: PropTypes.bool,
  /**
   * What to display on the table when there are no more
   * rows/resources to display; from the CrudTablePage
   */
  noResultsSettings: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
  }),
  /**
   * Callback to run when the active tab on the table is changed.
   * Is passed exactly 1 argument; the value of the tab clicked on
   */
  onChangeActiveTab: PropTypes.func,
  /**
   * Callback to run when the any of custom filter selects are
   * changed. It is passed 2 arguments; the customFilter name and
   * the newly changed value
   */
  onChangeSelectedCustomFilter: PropTypes.func,
  /**
   * Callback that receives the selected resource (from the
   * row that was clicked) as a single argument;
   */
  onClickRow: PropTypes.func,
  /**
   * Callback with that triggers when the fetch call in
   * withAsync is successfully loaded. Is is not passed any
   * arguments (prop used in withAsync HOC)
   */
  onTableDataLoaded: PropTypes.func,
  /**
   * Pagination settings object passed in as prop from
   * TabTable
   */
  paginationSettings: PropTypes.shape({
    paginate: PropTypes.bool
  }),
  /**
   * Pagnation state array; first index is the actual pagination
   * object (see shape) and the 2nd index in the array is the
   * setter function for the managed pagination state
   * (PropTypes doesn't support mixed type arrays so here is the shape)
   *
   *  pagnationState: {
   *      limit: PropTypes.number,
   *      filters: PropTypes.object,
   *      skiptoken: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
   *      page: PropTypes.number,
   *      sort: PropTypes.string,
   *  }
   *
   */
  paginationState: PropTypes.array,
  /**
   * The parameters object from TabTable to be used/ sent
   * along with every request
   */
  params: PropTypes.object,
  /**
   * Parameters that you wish to send along with every
   * request that includes your key values pairs within
   * the pagination query parameter object
   */
  filterParams: PropTypes.object,
  /**
   * The key name the data will be attached to when the
   * fetch request for the url prop is completed. If null it
   * will attempt to create a key from the path name
   */
  responseKey: PropTypes.string,

  /**
   * Callback function that is used when a table row is clicked.
   * Is is passed one argument (the single row from the tableRow array config)
   * The return result is passed to history.push(rowLink(row))
   */
  rowLink: PropTypes.func,

  /**
   * Object of search setting configurations
   * queryFields is an array of fields found in your
   * choosen model/resource (user has firstName etc...)
   * that will be searched when text is typed in the searchbar
   *
   * placeholder can be a function or string
   * If it's a function it will be given a count (# of records)
   * value by TabTable as an argument which you can use
   * to return/construct a new placeholder string
   */
  searchSettings: PropTypes.shape({
    placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    queryFields: PropTypes.arrayOf(PropTypes.string)
  }),

  /**
   * Weather or not to filter the data on the client
   * side or server side
   */
  server: PropTypes.bool,

  /**
   * Size of the table
   */
  size: PropTypes.oneOf(['small', 'medium']),

  /**
   * An array of options to be rendered in the sort select;
   * the format of the value key within the objects should be
   * one of the following enum values
   */
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOf([
        'name|asc',
        'name|desc',
        'createdAt|desc',
        'createdAt|asc',
        'dateCreated|desc',
        'dateCreated|asc',
        'id|asc',
        'id|desc',
        'calledAt|desc',
        'calledAt|asc',
      ]).isRequired,
      label: PropTypes.string.isRequired
    })
  ),

  /**
   * Additonal Config for sorting select dropdown ;
   * takes precedence over sortOptions prop
   */
  sortSettings: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOf([
          'name|asc',
          'name|desc',
          'createdAt|desc',
          'createdAt|asc',
          'dateCreated|desc',
          'dateCreated|asc',
          'id|asc',
          'id|desc',
          'calledAt|desc',
          'calledAt|asc',
        ]).isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    columnClickable: PropTypes.bool,
    disabled: PropTypes.bool
  }),

  /**
   *  skiptoken is used only by the server implementation (server= true)
   * and holds a number; the id of the last record fetched
   * into your table, it holds a cursor to let us know where
   * to continue reading data. (e.g. what other ids to skip)
   * In the event skiptoken is false, the pagination of the data is
   * at the end
   */
  skiptoken: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  /**
   * Array of data from withAsync HOC; used to create
   * table rows
   */
  tableData: PropTypes.array,

  /**
   *  TableCell component props from Material UI; can
   *  also include a style prop
   * https://v4.mui.com/api/table-cell/#tablecell-api
   */
  tableCellProps: PropTypes.object,

  /**
   * Weather or not to render a border on table columns
   */
  tableColBorder: PropTypes.bool,

  /**
   * Used to generate TableHeaders; each index corresponds
   * to a header
   * Shape of returned array is
   *     { width: "3%", text: "Avatar" },
   *     { width: "12%", text: "First Name" },
   *     { width: "12%", text: "Last Name" },
   *     { width: "15%", text: "Email" },
   *     { width: "10%", text: "Roles" },
   *     { width: "16%", text: "Date Added" },
   *     "Actions",
   *     Can be an object or just a string (uses auto width)
   */
  tableHeadDisplay: PropTypes.func,

  /**
   * Class to apply to tableRows; not in use within
   * this component
   * TODO: Figure out where this is used
   */
  tableRowClass: PropTypes.func,

  /**
   * Callback which is essentially identical to the tableRows function
   * passed into CrudTablePage's tableSettings Prop but with an additonal
   * set of actions appended as the last item (actions) of the row
   */
  tableRowDisplay: PropTypes.func,

  /**
   *  Tabs confirguation object for rendering tabs on
   * the CrudTablePage ; leave null if not used
   * TODO: Render as example
   */
  tabs: PropTypes.object,

  /**
   * Title of the CSV file of when table is downloaded
   */
  title: PropTypes.string,

  /**
   * Set by withAsync HOC; set from the fetched response and the
   * total number of resources in the returned data array
   */
  totalCount: PropTypes.number,

  /**
   * Url to make the fetch request to
   */
  url: PropTypes.string,

  /**
   * Boolean from TabTable that incidates weather or not to
   * wrap TabTable in the withLoadMore HOC; the conditional check for
   * this is done in TabTable so this prop isn't used here
   */
  loadMoreButton: PropTypes.bool,

  /**
   * The GQL service (has endpoint and methods for GQL calls setup) you wish to invoke
   * for the initial fetch of data to populate the table (e.g. dispatchService)
   */
  gqlService: PropTypes.object,

  /**
   * A method (static or on the instance) to be invoked by your gqlService prop to facilitate the
   * initial data fetch
   */
  gqlServiceInitialMethod: PropTypes.string,

  /**
   * Params to be passed into the gqlServiceInitialMethod on your gqlService instance (if not null)
   */
  gqlServiceInitialParams: PropTypes.string,

  /**
   * Boolean to indicate that the initial fetch of the tableData should
   * be from a GraphQL endpoint; this alters 3 things about this components behavior
   *  1) The useFetch hook ignores the url and other params
   *  2) The first call is now fetched through the passed gqlService[gqlServiceInitialMethod](gqlServiceInitialParams)
   */
  isGraphQLQuery: PropTypes.bool,

  /**
   * A callback function used to transform the response data from the initial fetch of the table.
   * Passed into the withAsync HOC component and useFetch() as the 4th argument (order matters, array destructuring is used)
   * called afterFetch. You must return the response in the expected shape (called with lodash get(response.data, responseKey))
   * indicated by your responseKey so withAsync is able to parse it correctly
   */
  afterInitialFetch: PropTypes.func,

  /**
   * Object to support table labels/text in different languages if desired,
   * for react-i18next useTranslation hook pass in your translated string (e.g. t('something'))
   */
  translationStrings: PropTypes.shape({
    downloadButtonText: PropTypes.string,
    sortByText: PropTypes.string,
    deleteButtonText: PropTypes.string,
    editButtonText: PropTypes.string,
    rowsPerPageText: PropTypes.string,
    ofNRowsText: PropTypes.string,
    moreThanNRowsText: PropTypes.string,
    loadMoreButtonText: PropTypes.string,
  })
};

TabTable.defaultProps = {
  tableData: [],
  idKey: 'id',
  isLoading: false,
  displayCheckBoxes: false,
  tableColBorder: true,
  downloadCsvButton: false,
  onChangeActiveTab: () => { },
  onChangeSelectedCustomFilter: () => { },
  onClickRow: () => { },
  paginationState: [{}, () => { }],
  tabs: null,
  isGraphQLQuery: false,
  afterInitialFetch: null,
  translationStrings: {
    downloadButtonText: "Download",
    sortByText: "Sort By",
    deleteButtonText: "Delete",
    editButtonText: "Edit",
    rowsPerPageText: "Rows per Page",
    ofNRowsText: "of",
    moreThanNRowsText: "more",
    loadMoreButtonText: "Load More"
  }
};

// load table using async endpoint if url is a prop
const withAsyncDataLoad = branch(
  (props) => props.url || props.isGraphQLQuery,
  withAsync
);

// add pagination unless specifically told not to
const withPaging = branch(
  (props) => props.paginationSettings?.paginate !== false,
  withPagination
);

const withLoadMoreButton = branch(
  (props) => props.loadMoreButton === true,
  withLoadMore
);

// Note that the order has reversed — props flow from top to bottom
// First withPaging, etc...
const TabTableWithDataSource = compose(
  withPaging,
  withAsyncDataLoad,
  withLoadMoreButton
)(TabTable);

export default forwardRef((props, ref) => {
  return <TabTableWithDataSource {...props} forwardref={ref} />;
});
