import React, { Suspense, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import DayjsUtils from '@date-io/dayjs';
import { SnackbarProvider } from 'notistack';
import {
  makeStyles,
  jssPreset,
  createStyles,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { setUpDayjs } from './utils/dayjsUtils';
import { useJsApiLoader } from '@react-google-maps/api';
import CountryProvider from './context/CountryProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Auth,
  CacheProvider,
  SystemAlert,
  GoogleAnalytics,
  setApplicationDetails,
  ScrollReset,
  PORTAL_TYPE,
} from '@nsd/fe';
import apps from 'src/apps';
import Routes from './Routes';
import { loadLocale } from './utils/language';
import useAgents from './hooks/useAgents';
import LoginMediaCard from 'src/components/LoginMediaCard';
import useInitialTheme from './hooks/useInitialTheme';
import LanguageSupportErrorFallback from './components/LanguageSupportErrorFallback';


const APP_VERSION = '2.0.0';
const APP_NAME = 'Client Qore';
const APP_END_USER = PORTAL_TYPE.CLIENT;
const APP_INTERNAL_NAME = 'client-qore';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const GOOGLE_SCRIPT_LIBRARIES = ['drawing', 'places'];
const GOOGLE_MAPS_STABLE_VERSION = '3.58';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
    logo: {
      width: '100%',
      height: theme?.logo?.login?.height,
    },
  })
);

setUpDayjs(); // Add several extensions for dayjs

function App() {
  const dispatch = useDispatch();
  const { removeCachedAgents } = useAgents();

  useEffect(() => {
    dispatch(
      setApplicationDetails(
        APP_END_USER,
        APP_NAME,
        APP_VERSION,
        APP_INTERNAL_NAME
      )
    );
  }, [dispatch]);

  const classes = useStyles();
  const { theme } = useInitialTheme();
  // Will silently fail without a Google API key
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps',
    /**
    * Traffic layer component issue with newer version of Google Maps Drawing API, need to downgrade
    * https://stackoverflow.com/questions/79273849/google-maps-react-component-crashes-with-trafficlayer-uncaught-error-b-369845
    */
    version: GOOGLE_MAPS_STABLE_VERSION,
    libraries: GOOGLE_SCRIPT_LIBRARIES,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  if (!theme){
    return null;
  } 
  
  if (!isLoaded) {
    return null;
  }

  return (
    <CacheProvider>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale={loadLocale()}>
              <SnackbarProvider maxSnack={1}>
                <Suspense fallback={<LanguageSupportErrorFallback />}>
                  <CountryProvider>
                    <Router history={history}>
                      <SystemAlert />
                      <Auth>
                        <ScrollReset />
                        <GoogleAnalytics />
                        <Routes
                          apps={apps || []}
                          onSubmitSuccess={removeCachedAgents}
                          logoConfig={{
                            logo: theme.logo?.login?.src,
                            logoAlt: 'Login Logo',
                            height: theme.logo?.login?.height,
                            className: classes.logo,
                          }}
                          formJSX={LoginMediaCard}
                        />
                      </Auth>
                    </Router>
                  </CountryProvider>
                </Suspense>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </DndProvider>
    </CacheProvider>
  );
}

export default App;
