import axios from 'axios';
import { APP_ENV, getEnvironment } from './env';
import { parseCookie } from './cookie';
import jwtDecode from 'jwt-decode';
import find from 'lodash/find';

const getCompany = () => {
  const { 'nsd.token': token } = parseCookie(document.cookie);
  if (!token) {
    return null;
  }

  const decoded = jwtDecode(token);
  const companyCode = find(decoded.userCompanyCodes, (company) =>
    company.includes(process.env.REACT_APP_COMPANY_CODE)
  );
  return companyCode;
};

const getNsdApiBaseUrl = () => {
  // To run static storybook site locally or on dedicated documentation site
  // if (
  //   process.env.STORYBOOK_REACT_APP_API &&
  //   (window.origin?.includes("localhost") ||
  //     window.origin?.includes("https://nsd-fe.azurewebsites.net/"))
  // ) {
  //   return `${window.origin}/api`;
  // }
  // // All APIs are controlled through env variables. No need to use anything else
  // if (!process.env.REACT_APP_COMMON_SERVICES_API) {
  //   throw Error(
  //     "Please set REACT_APP_COMMON_SERVICES_API environment variable in your current environemnt"
  //   );
  // }
  return process.env.REACT_APP_COMMON_SERVICES_API;
};

const getNsdClientQoreApiBaseUrl = () => {
  // To run static storybook site locally or on dedicated documentation site
  // if (
  //   process.env.STORYBOOK_REACT_APP_API &&
  //   (window.origin?.includes("localhost") ||
  //     window.origin?.includes("https://nsd-fe.azurewebsites.net/"))
  // ) {
  //   return `${window.origin}/api`;
  // }
  // // All APIs are controlled through env variables. No need to use anything else
  // if (!process.env.REACT_APP_COMMON_SERVICES_API) {
  //   throw Error(
  //     "Please set REACT_APP_COMMON_SERVICES_API environment variable in your current environemnt"
  //   );
  // }
  return process.env.REACT_APP_CLIENT_QORE_API;
};

const getAPIHeader = (env) => {
  return {
    common: {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_NSD_API_KEY,
      'Company-Code': getCompany() || process.env.REACT_APP_COMPANY_CODE,
      'Suite-Identifier': process.env.REACT_APP_SUITE_IDENTIFIER,
    },
  };
};
const nsdAPIHeader = getAPIHeader(getEnvironment());

const instance = axios.create({
  headers: nsdAPIHeader,
  withCredentials: true,
  baseURL: getNsdApiBaseUrl(),
});

instance.interceptors.request.use(
  (config) => {
    // use functions directly instead of api management for develop and staging
    let apiCode = process.env.REACT_APP_API_CODE;
    const environment = getEnvironment();
    if (environment === APP_ENV.STAGING) {
      apiCode = process.env.REACT_APP_API_CODE_STAGING;
    }
    if (apiCode && environment !== APP_ENV.PRODUCTION) {
      config.params = {
        ...config.params,
        code: apiCode,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const isCancel = axios.isCancel;
const CancelToken = axios.CancelToken;

export default instance;
export {
  CancelToken,
  isCancel,
  nsdAPIHeader,
  getNsdApiBaseUrl,
  getNsdClientQoreApiBaseUrl,
};
